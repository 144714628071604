import _base from "./driver/base";
import _client from "./driver/client";
import _server from "./driver/server";
var exports = {};
// Protocol references:
//
// * http://tools.ietf.org/html/draft-hixie-thewebsocketprotocol-75
// * http://tools.ietf.org/html/draft-hixie-thewebsocketprotocol-76
// * http://tools.ietf.org/html/draft-ietf-hybi-thewebsocketprotocol-17
var Base = _base,
    Client = _client,
    Server = _server;
var Driver = {
  client: function (url, options) {
    options = options || {};
    if (options.masking === undefined) options.masking = true;
    return new Client(url, options);
  },
  server: function (options) {
    options = options || {};
    if (options.requireMasking === undefined) options.requireMasking = true;
    return new Server(options);
  },
  http: function () {
    return Server.http.apply(Server, arguments);
  },
  isSecureRequest: function (request) {
    return Server.isSecureRequest(request);
  },
  isWebSocket: function (request) {
    return Base.isWebSocket(request);
  },
  validateOptions: function (options, validKeys) {
    Base.validateOptions(options, validKeys);
  }
};
exports = Driver;
export default exports;